import { configureStore } from '@reduxjs/toolkit'
import { unifiedApi } from "./services/unifiedAPIQuery"
import userSlice from './Slices/userSlice'
import tooltipsSlice from './Slices/tooltipsSlice'
import vehiclesSlice from './Slices/vehiclesSlice'


export const store = configureStore({
  reducer: {
    user: userSlice,
    tooltips: tooltipsSlice,
    vehicles: vehiclesSlice,
    [unifiedApi.reducerPath]: unifiedApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(unifiedApi.middleware)
})
