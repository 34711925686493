const DEFAULT_VEHICLE = "Hyundai_Tucson_PHEV_2023";
const DEFAULT_VEHICLE_TRIM = "SEL";
const VEHICLE_DATA = [
       {
              "id": "Hyundai_Kona_Electric__BEV_2023",
              "label": "KONA Electric",
              "trims": [
                     {
                            "handle": "Hyundai_Kona_Electric_SE_BEV_2023",
                            "name": "SE",
                            "displayName": "SE $33,550",
                            "msrp": 33550,
                            "maintenance": 0,
                     },
                     {
                            "handle": "Hyundai_Kona_Electric_SEL_BEV_2023",
                            "name": "SEL",
                            "displayName": "SEL $37,300",
                            "msrp": 37300,
                            "maintenance": 0,
                     },
                    {
                            "handle": "Hyundai_Kona_Electric_Limited_BEV_2023",
                            "name": "Limited",
                            "displayName": "Limited $41,550",
                            "msrp": 41550,
                            "maintenance": 0,
                     }
              ]
       }, {
              "id": "Hyundai_Tucson_PHEV_2023",
              "label": "TUCSON Plug-in Hybrid",
              "trims": [
                     {
                            "handle": "Hyundai_Tucson_PHEV_2023",
                            "name": "SEL",
                            "displayName": "SEL $37,050",
                            "msrp": 37050,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Tucson_PHEV_2023",
                            "name": "Limited",
                            "displayName": "Limited $44,310",
                            "msrp": 44310,
                            "maintenance": 0
                     }
              ]
       }, {
              "id": "Hyundai_Ioniq_5_Standard_Range_RWD_BEV_2023",
              "label": "IONIQ 5",
              "trims": [
                     {
                            "handle": "Hyundai_Ioniq_5_Standard_Range_RWD_BEV_2023",
                            "name": "SE STANDARD RANGE RWD",
                            "displayName": "SE STANDARD RANGE RWD $41,450",
                            "msrp": 41450,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_5_SE_RWD_BEV_2023",
                            "name": "SE RWD",
                            "displayName": "SE RWD $45,500",
                            "msrp": 45500,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_5_SEL_RWD_BEV_2023",
                            "name": "SEL RWD",
                            "displayName": "SEL RWD $47,450",
                            "msrp": 47450,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_5_SE_AWD_BEV_2023",
                            "name": "SE AWD",
                            "displayName": "SE AWD $49,000",
                            "msrp": 49000,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_5_SEL_AWD_BEV_2023",
                            "name": "SEL AWD",
                            "displayName": "SEL AWD $50,950",
                            "msrp": 50950,
                            "maintenance": 0
                     },      
                     {
                            "handle": "Hyundai_Ioniq_5_Limited_RWD_BEV_2023",
                            "name": "Limited RWD",
                            "displayName": "Limited RWD $52,600",
                            "msrp": 52600,
                            "maintenance": 0
                     },  
                     {
                            "handle": "Hyundai_Ioniq_5_SEL_AWD_BEV_2023",
                            "name": "Limited AWD",
                            "displayName": "Limited AWD $56,500",
                            "msrp": 56500,
                            "maintenance": 0
                     }                
              ]
       },
       {
              "id": "Hyundai_Santa_Fe_PHEV_2023",
              "label": "SANTA FE Plug-in Hybrid",
              "trims": [
                     {
                            "handle": "Hyundai_Santa_Fe_PHEV_2023",
                            "name": "SEL CONVENIENCE",
                            "displayName": "SEL CONVENIENCE $39,500",
                            "msrp": 39500,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Santa_Fe_PHEV_2023",
                            "name": "Limited",
                            "displayName": "Limited $45,510",
                            "msrp": 45510,
                            "maintenance": 0
                     }
              ]
       },
{
              "id": "Hyundai_Ioniq_6_SE_RWD_BEV_2023",
              "label": "IONIQ 6",
              "trims": [
                     {
                            "handle": "Hyundai_Ioniq_6_SE_Standard_RWD_BEV_2023",
                            "name": "SE Standard Range",
                            "displayName": "SE Standard Range ($41,600)",
                            "msrp": 47700,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_6_SE_RWD_BEV_2023",
                            "name": "SE RWD",
                            "displayName": "SE (RWD) $45,500",
                            "msrp": 45500,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_6_SEL_RWD_BEV_2023",
                            "name": "SEL RWD",
                            "displayName": "SEL (RWD) $47,700",
                            "msrp": 47700,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_6_SE_AWD_BEV_2023",
                            "name": "SE HTRAC AWD",
                            "displayName": "SE (HTRAC AWD) $49,000",
                            "msrp": 49000,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_6_Limited_RWD_BEV_2023",
                            "name": "Limited RWD",
                            "displayName": "Limited (RWD) $52,600",
                            "msrp": 52000,
                            "maintenance": 0
                     },
                     {
                            "handle": "Hyundai_Ioniq_6_Limited_AWD_BEV_2023",
                            "name": "Limited AWD",
                            "displayName": "Limited (HTRAC AWD) $56,100",
                            "msrp": 56100,
                            "maintenance": 0
                     },
              ]
       }
];

export {
       DEFAULT_VEHICLE,
       DEFAULT_VEHICLE_TRIM,
       VEHICLE_DATA
}