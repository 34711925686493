const EN = {
       defaultIncentivesTooltip: "Depending upon the purchaser's state of residence, additional state and/or utility incentives may be available. Tax credits and additional incentives are not within Hyundai's control and are subject to change or expire without notice. This information does not constitute tax, financial or legal advice. Please consult your tax, financial or legal professional to determine eligibility, amount of credits and/or incentives available, if any, and further details. For additional information regarding federal, state and utility incentives please see: {link}",
       santafeIncentivesTooltip: "Depending on the purchaser's state of residence, certain state tax credits and state and/or utility incentives may be available. These potential tax credits and incentives are not within Hyundai's control, are subject to change or expire without notice, and are referenced for informational purposes only. Eligibility for tax credits depends on the purchaser's tax liability and other factors. Tax credits are potential future benefits and not applied at the time of purchase. This information does not constitute tax, financial or legal advice. Please consult with your tax, financial or legal professional to determine eligibility, amount of credits and/or incentives available, if any, and further details. For additional information regarding state and utility incentives see: {link}",
       santafeSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on 2023 SANTA FE Plug-in Hybrid's EPA-estimated rating of 33 MPG combined (gas) and 76 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, fuel prices, electricity prices, the percentage of miles the SANTA FE Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of SANTA FE Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary. Actual fuel savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium-ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       konaSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on KONA Electric's EPA-estimated rating of 120 MPGe combined and consumer input regarding the number of miles driven annually, fuel prices, electricity prices, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of KONA Electric. Actual fuel savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium-ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       tucsonSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on TUCSON Plug-in Hybrid's EPA-estimated rating of 35 MPG combined (gas) and 80 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, fuel prices, electricity prices, the percentage of miles the TUCSON Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of TUCSON Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary. Actual fuel savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium-ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       ioniqHybridSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on IONIQ Plug-in Hybrid's EPA-estimated rating of 52 MPG combined (gas) and 119 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, fuel prices, electricity prices, the percentage of miles the IONIQ Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of IONIQ Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary. Actual fuel savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       ioniqHybridAWDSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on IONIQ 5's efficiency rating of 98 MPGe combined (SE/SEL/Limited AWD), consumer input regarding the number of miles driven annually, fuel prices, electricity prices, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary. Actual savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       ioniqSavingsText: "Potential savings over five years is based on a combination of (i) potential tax credits and incentives and (ii) potential fuel savings over five years. Potential tax credits, incentives and fuel savings are referenced for informational purposes only. Eligibility for tax credits and incentives will vary. Estimated potential fuel savings over five years based on IONIQ 5's efficiency rating of 114 MPGe combined (SE/SEL/Limited RWD), consumer input regarding the number of miles driven annually, fuel prices, electricity prices, and the estimated combined MPG of a comparison gas-powered vehicle. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary. Actual savings, if any, will vary and depend on conditions such as external elements, driving and charging habits, fuel/electricity price fluctuations, vehicle condition, and lithium ion battery age. See Incentives tab for details regarding tax credits and incentives.",
       defaultEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on KONA Electric's EPA-estimated rating of 120 MPGe combined, consumer input regarding the number of miles driven annually and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of KONA Electric.",
       santafeEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on SANTA FE Plug-in Hybrid's EPA-estimated rating of 33 MPG combined (gas) and 76 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, the percentage of miles the SANTA FE Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of SANTA FE Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary.",
       konaEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on KONA Electric's EPA-estimated rating of 120 MPGe combined, consumer input regarding the number of miles driven annually and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of KONA Electric.",
       tucsonEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on TUCSON Plug-in Hybrid's EPA-estimated rating of 35 MPG combined (gas) and 80 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, the percentage of miles the TUCSON Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of TUCSON Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary.",
       ioniqHybridEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on IONIQ Plug-in Hybrid's EPA-estimated rating of 52 MPG combined (gas) and 119 MPGe combined when running on all-electric power and consumer input regarding the number of miles driven annually, the percentage of miles the IONIQ Plug-in Hybrid will be driven on all-electric power, and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of IONIQ Plug-in Hybrid. EPA estimates are for comparison purposes only and actual mileage will vary.",
       ioniqAWDEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on IONIQ 5's efficiency rating of 98 MPGe combined (SE/SEL/Limited AWD), consumer input regarding the number of miles driven annually and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary.",
       ioniqEmissionsText: "Carbon emissions estimates are referenced for informational purposes only. Actual carbon emissions will vary. Comparison of estimated driving-related emissions over five years is based on IONIQ 5's efficiency rating of 114 MPGe combined (SE/SEL/Limited RWD), consumer input regarding the number of miles driven annually and the estimated combined MPG of a comparison gas-powered vehicle. Gasoline emissions are calculated at 19.6 lbs. of carbon dioxide per gallon of gas and electricity-related emissions are calculated based on the average carbon intensity of the consumer's local grid. Assumes home charging of IONIQ 5. EPA estimates are for comparison purposes only and actual mileage will vary.",
};

export default EN;