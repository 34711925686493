import BarGraph from "../components/Graphs/BarGraph/BarGraph"
import calcEmissions from '../functions/vehicle/emissions/calcEmissions';
import { LBC02_PER_GALLON_OF_GAS } from "../data/assumptions/Assumptions"
import SelectAnnualMileage from "../components/Inputs/Select/SelectAnnualMileage"
import TabTitle from "../components/TabTitle"
import SlideEquivalentGasMPG from "../components/Inputs/Slider/SlideEquivalentGasMPG";
import SelectTrim from "../components/Inputs/Select/SelectTrim";
import SelectVehicle from "../components/Inputs/Select/SelectVehicle";
import SlidePercentElectric from "../components/Inputs/Slider/SlidePercentElectric";
import { useSelector } from 'react-redux';
import TextFieldZipcode from "../components/Inputs/TextField/TextFieldZipcode"
import Grid from '@mui/material/Grid';
import TabStructure from "../components/TabStructure";
import { FormatAsPercent, FormatAsThousands } from "../utils/Format";
import { isPHEV } from "../functions/vehicle/vehicleHelper";
import { getTooltips } from "../redux/selectors";

const Emissions = ({ vehicle, location, activeTrim }) => {
    const { annualMileage, equivalentGasMPG, percentElectric, numYears } = useSelector(state => state.user)
    const tooltip = useSelector(getTooltips)

    const gasEmission = calcEmissions.gasEmitted(annualMileage, equivalentGasMPG, LBC02_PER_GALLON_OF_GAS.value) * numYears

    const evEmission = calcEmissions.evEmitted(annualMileage, vehicle.electric_efficiency, location.regional_electricity[0].emissions.emissions_co2, vehicle.fossil_fuel_efficiency, LBC02_PER_GALLON_OF_GAS.value, vehicle, percentElectric) * numYears

    const graphData = [gasEmission, evEmission]
    const labels = [['Gas-only', 'vehicle'], `${vehicle.model_year} ${vehicle.model}`]
    const backgroundColor = ['#000000', '#00AAD2']

    const inputs = [
        <TextFieldZipcode />,
        <SelectAnnualMileage />,
        <SlideEquivalentGasMPG type={vehicle.fuel} />,
    ]

    isPHEV(vehicle) && inputs.push(<SlidePercentElectric />)

    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name} item xs={12}>
                    {input}
                </Grid>
            )
        })
    }

    const getDisclaimer = () => {
       if (vehicle.model.includes("KONA") || vehicle.model.includes("IONIQ") || vehicle.model.includes("TUCSON") || vehicle.model.includes('SANTA')) {
         return "Highest trim with optional features shown.";
       } else {
         return "2022 model shown. 2023 model may vary.";
       }
     }

     const getVehicleImage = (vehicle) => {
       return vehicle.images[0].url_full;
     }

    const carbonSavings = gasEmission - evEmission
    const carbonPercentage = FormatAsPercent(carbonSavings / gasEmission * 100)

    const left = 
    <>
       <TabTitle
              header={`Reduce your emissions from<br/> driving by up to ${carbonPercentage}.`}
              subHeader={`Over 5 years, that could keep up to ${FormatAsThousands(carbonSavings)} lbs. of CO${String.fromCodePoint(8322)} out of our atmosphere`}
              tooltip={tooltip?.emissionsTip}
              tooltipPlacement="left"
       />
       <Grid container rowSpacing={2} style={{margin: '25px auto 0px'}} className="form-fields-grid">
        <Grid item xs={12}>
            <SelectVehicle />
            &nbsp;
            <SelectTrim />
            <div className="vehicle-image" style={{margin:'25px auto'}}>
              <img src={getVehicleImage(vehicle)} alt="Vehicle" style={{width:'100%'}} />
            </div>
            &nbsp;
            <p className="vehicle-disclaimer" style={{fontSize:"12px", color:"#666666", textAlign:"center"}}>{getDisclaimer()}</p>
        </Grid>
      </Grid>
    </>

    const middle = <BarGraph
        header={'Estimated carbon emissions from driving over 5 years'} graphData={graphData}
        labels={labels}
        backgroundColor={backgroundColor}
    />

    const right = <Grid container rowSpacing={2} style={{maxWidth:'300px', margin: '0 auto'}} className="form-fields-grid">
        {renderInputs(inputs)}
    </Grid >

    return (
        <TabStructure
            left={left}
            middle={middle}
            right={right}
        />
    )
}

export default Emissions
